import { Script } from 'gatsby';
import React from 'react';
import defaultOptions from './default-options';
import { isDefined, oneline } from './utils';

const HSScripts = ({ pluginOptions }) => {

  const options = {
    ...defaultOptions,
    ...pluginOptions
  };

  if (
    (options.productionOnly && process.env.NODE_ENV !== 'production') ||
    !isDefined(options.trackingCode)
  ) {
    return null;
  }


  return [
    <Script
      key={"hs-load"}
      strategy="off-main-thread"
      forward={["_hsq.push"]}
      src={`https://js.hs-scripts.com/26918904.js`}
    />,
    <Script
      strategy="off-main-thread"
      key={'hs-script-actual'}
      dangerouslySetInnerHTML={{
        __html: oneline`
          var _hsq = window._hsq = window._hsq || [];
          _hsq.push(['setPath', window.location.pathname + window.location.search + window.location.hash]);
          ${
          options.respectDNT
            ? `
                  if (window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack || 'msTrackingProtectionEnabled' in window.external) {
                    if (window.doNotTrack == '1' || navigator.doNotTrack == 'yes' || navigator.doNotTrack == '1' || navigator.msDoNotTrack == '1' || window.external.msTrackingProtectionEnabled()) {
                      _hsq.push(['doNotTrack']);
                    }
                  }
                `
            : ``
        }
        `
      }}
    />
  ];

};

export const wrapPageElement = ({ element, props, ...rest }, pluginOptions) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <>
      {element}
      <HSScripts pluginOptions={pluginOptions} />
    </>
  );
};